import React from "react";

function UserProfile({ userData }) {
  if (!userData) {
    return <p>Loading...</p>;
  } else {
    const {
      player: { personaname, avatarfull, steamid },
    } = userData.user || {};

    const { player_level } = userData.steam_level || {};
    const { total_games, total_game_hours, total_game_price } = userData || {};

    const games = userData.owned_games || [];

    return (
      <div className="flex flex-col items-center justify-start min-h-screen text-center bg-gray-100 p-6">
        <h1 className="text-6xl font-bold mb-8 mt-8">
          Welcome, {personaname}{" "}
        </h1>
        <img
          className="rounded-full mb-6 w-32 h-32"
          src={avatarfull}
          alt="User Avatar"
        />
        <p className="mb-6 text-lg">Here is your Steam statistics:</p>

        <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md mb-8">
          <div className="text-left">
            <p>
              <strong>Steam ID:</strong> {steamid}
            </p>
            <p>
              <strong>Steam Level:</strong> {player_level || "N/A"}
            </p>
            <p>
              <strong>Total Games:</strong> {total_games}
            </p>
            <p>
              <strong>Total Playtime:</strong> {total_game_hours} hours
            </p>
            <p>
              <strong>Total Game Price:</strong> {total_game_price} ₽
            </p>
          </div>
        </div>

        <div className="w-full max-w-4xl">
          <h2 className="text-4xl font-bold mb-6">Your Games</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {games
              .sort((a, b) => b.playtime_forever - a.playtime_forever) // Сортировка по playtime_forever в порядке убывания
              .map((game) => (
                <div
                  key={game.appid}
                  className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center"
                >
                  <img
                    src={`https://cdn.akamai.steamstatic.com/steam/apps/${game.appid}/capsule_sm_120.jpg`}
                    alt={game.name}
                    className="w-30 h-12 mb-4"
                  />
                  <p className="text-lg font-semibold">{game.name}</p>
                  <p className="text-sm text-gray-500">
                    Playtime: {Math.round(game.playtime_forever / 60)} hours
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
