import { useEffect } from 'react';

function LoginPage() {
  useEffect(() => {
    // Перенаправление на страницу авторизации Steam
    window.location.href = 'https://devherbarium.ru/api/auth/steam';
  }, []);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <h1 className="text-xl font-semibold">Redirecting to Steam...</h1>
    </div>
  );
}

export default LoginPage;
