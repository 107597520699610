import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import UserProfile from './UserProfile';
import { useUnit } from "effector-react";
import { userData , getUserData } from '../models/UserData';

function HomePage() {
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [steamId, setSteamId] = useState(null);

  const data = useUnit(userData);

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };
    
    const steamId = getCookie('user');
    console.log(steamId);
    if (steamId) {
      setSteamId(steamId);
    }
  }, [cookies]);

  useEffect(()=>{
    if(steamId != null){
      getUserData(steamId);
    }
  }, [steamId]);

  const handleLogout = () => {
    removeCookie('user');
    window.location.href = '/';
  };

  if (!steamId) {
    return (
      <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100">
        <h1 className="text-4xl font-bold mb-6">Welcome to Steam Stats App</h1>
        <p className="mb-6 text-lg">This application is safe and used to display your Steam account statistics.</p>
        <a
          href="/login"
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
        >
          Sign in with Steam
        </a>
      </div>
    );
  }

  

  return (
    <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100">
  <button
    onClick={handleLogout}
    className="fixed top-0 left-5 px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 mt-6 z-50"
  >
    Logout
  </button>
  <UserProfile userData={data} />
</div>

  );
}

export default HomePage;
