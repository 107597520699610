import {createStore, createEffect} from "effector";

async function fetchUserData(steamId) {
    if (!steamId) return;
    try {
      const response = await fetch(`https://devherbarium.ru/api/user/${steamId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
}

export const getUserData = createEffect(async (steamId) => {
    const data = await fetchUserData(steamId);
    return data;
})

export const userData = createStore(null).on(getUserData.doneData,(_,data)=>data)

